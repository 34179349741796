import React from 'react'

import { Helmet } from 'react-helmet'

import './terms-of-service.css'

const TermsOfService = (props) => {
  return (
    <div className="terms-of-service-container">
      <Helmet>
        <title>
          Terms-of-Service - Flyzy - Optimizing Travel Industry via Innovation
        </title>
        <meta
          name="description"
          content="Trusted by 10+ airport operators, 15+ airlines &amp; 200+\ntravel agents across globe"
        />
        <meta
          property="og:title"
          content="Terms-of-Service - Flyzy - Optimizing Travel Industry via Innovation"
        />
        <meta
          property="og:description"
          content="Trusted by 10+ airport operators, 15+ airlines &amp; 200+\ntravel agents across globe"
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/66f72b47-3f73-4e1c-835c-789471c20b68/cd776514-4699-4b91-b687-bf38db8a348f?org_if_sml=1"
        />
      </Helmet>
      <iframe
        src="https://flyzygo-website-v21.firebaseapp.com/terms_and_conditions"
        allowFullScreen
        className="terms-of-service-iframe"
      ></iframe>
    </div>
  )
}

export default TermsOfService
