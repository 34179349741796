import React from 'react'

import { Helmet } from 'react-helmet'

import './bilty.css'

const Bilty = (props) => {
  return (
    <div className="bilty-container">
      <Helmet>
        <title>Flyzy - Optimizing Travel Industry via Innovation</title>
      </Helmet>
      <div className="bilty-bilty">
        <div className="bilty-frame1">
          <div className="bilty-frame2">
            <div className="bilty-frame3">
              <img
                alt="image1639"
                src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/66f72b47-3f73-4e1c-835c-789471c20b68/57ef434c-5c9b-4139-b6ab-e257742e66c4?org_if_sml=12677"
                className="bilty-image1"
              />
            </div>
            <span className="bilty-text">
              <span>INSTANT BILTY</span>
            </span>
          </div>
          <div className="bilty-frame29">
            <div className="bilty-frame39">
              <span className="bilty-text02">
                <span>BOOKING REF No:</span>
              </span>
            </div>
            <span className="bilty-text04">
              <span>#1320</span>
            </span>
          </div>
          <div className="bilty-frame36">
            <div className="bilty-frame37">
              <span className="bilty-text06">
                <span>PICKUP TIME :</span>
              </span>
            </div>
            <span className="bilty-text08">
              <span>SACHDEVA SALES CORPORATION PRIVATE LIMITED</span>
            </span>
          </div>
          <div className="bilty-frame30">
            <div className="bilty-frame361">
              <span className="bilty-text10">
                <span>TRANSPORTER :</span>
              </span>
            </div>
            <div className="bilty-frame33">
              <img
                alt="image3660"
                src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/66f72b47-3f73-4e1c-835c-789471c20b68/ba53e84f-2a5d-40fa-9221-5bad01cff20a?org_if_sml=111719"
                className="bilty-image3"
              />
              <span className="bilty-text12">
                <span>LALJI MULJI TRANSPORT CORPORATION PRIVATE LIMITED</span>
              </span>
            </div>
          </div>
          <div className="bilty-frame31">
            <div className="bilty-frame371">
              <span className="bilty-text14">
                <span>CONSIGNOR :</span>
              </span>
            </div>
            <span className="bilty-text16">
              <span>SACHDEVA SALES CORPORATION PRIVATE LIMITED</span>
            </span>
          </div>
          <div className="bilty-frame32">
            <div className="bilty-frame38">
              <span className="bilty-text18">
                <span>CONSIGNEE :</span>
              </span>
            </div>
            <span className="bilty-text20">
              <span>AZAD21, CHENNAI, TAMIL NADU, 600002</span>
            </span>
          </div>
          <div className="bilty-frame34"></div>
          <div className="bilty-frame35">
            <div className="bilty-group1">
              <img
                alt="Vector6100"
                src="/playground_assets/vector6100-873.svg"
                className="bilty-vector"
              />
              <img
                alt="Vector6101"
                src="/playground_assets/vector6101-vdeo.svg"
                className="bilty-vector1"
              />
              <img
                alt="Vector6102"
                src="/playground_assets/vector6102-krbi.svg"
                className="bilty-vector2"
              />
              <img
                alt="Vector6103"
                src="/playground_assets/vector6103-n4p.svg"
                className="bilty-vector3"
              />
            </div>
            <span className="bilty-text22">
              <span>
                Bilty generated at the time of parcel(s) pickup, Transporter
                LR/GR/Consignment Note will be updated later
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Bilty
